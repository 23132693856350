import ImageCompressor from "image-compressor.js";

function compressImage(fileObj) {
    return new Promise((resolve, reject) => {
        const options = {
            maxWidth: 700,
            maxHeight: 700,
            quality: 0.8,
            success(result) {
                // Resolve the promise with the compressed image
                resolve(result);
            },
            error(e) {
                console.error('Image compression error:', e);

                // Reject the promise with an error message
                reject(e);
            },
        };

        new ImageCompressor().compress(fileObj, options);
    });
}

export default compressImage;