import {
  ROUTE_ADMIN,
  ROUTE_PROFILE_LOGIN,
  ROUTE_PROFILE_VIEW,
  ROUTE_REGISTRATION,
} from "Constants";
import { Navigate, Route, Routes as Switch } from "react-router-dom";
import {
  NotFoundPage,
  ProfileLoginPage,
  ProfileViewPage,
  RegistrationPage,
  AdminPanelPage,
} from "Pages";

function Routes() {
  return (
    <Switch>
      <Route path="/" element={<Navigate to={ROUTE_REGISTRATION} replace />} />
      <Route path={ROUTE_REGISTRATION} element={<RegistrationPage />} />
      <Route path={ROUTE_PROFILE_LOGIN} element={<ProfileLoginPage />} />
      <Route path={ROUTE_PROFILE_VIEW} element={<ProfileViewPage />} />
      <Route path={ROUTE_ADMIN} element={<AdminPanelPage />} />
      <Route path={"*"} element={<NotFoundPage />} />
    </Switch>
  );
}

export default Routes;
