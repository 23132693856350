import Layout from "../Layout";
import { AllUsersDatagrid } from "Components/Common";
import { Container } from "@mui/material";
import { useState } from "react";
import { AdminLogin } from "Components/Forms";

function AdminPanelPage() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleLogin = () => {
        setIsLoggedIn(true);
    };

    return (
        <Layout>
            <Container>
                {isLoggedIn ? (
                    <AllUsersDatagrid />
                ) : (
                    <AdminLogin onLogin={handleLogin} />
                )}
            </Container>
        </Layout>
    );
}

export default AdminPanelPage;
