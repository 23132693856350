

function showSuccess({ title = "Success", message = "" }) {
    return window.Swal.fire(
        title,
        message,
        'success'
    );
}

function showError({ title = "Error", message = "" }) {
    return window.Swal.fire(
        title,
        message,
        'error'
    );
}

function formatMobileNumber(mobileNumber) {
    const formattedNumber = mobileNumber.replace(/[^0-9\s]+/g, ""); // Remove non-digit characters except spaces
    const index = formattedNumber.indexOf(" "); // Find the index of the first empty space
    const countryCode = formattedNumber.slice(0, index); // Extract the country code
    const phoneNumber = formattedNumber.slice(index + 1).replace(/\s/g, ""); // Extract the phone number and remove all spaces
    const finalMobileNumber = `${countryCode}-${phoneNumber}`;
    if (finalMobileNumber.length < 6) {
        return "";
    }
    return finalMobileNumber;
};

function jsonToFormData(json) {
    const formData = new FormData();

    for (const key in json) {
        if (json.hasOwnProperty(key)) {
            formData.append(key, json[key]);
        }
    }

    return formData;
}



export { showSuccess, showError, formatMobileNumber, jsonToFormData };