import styled from "@emotion/styled";
import { Avatar, Backdrop, Box, Card, CardContent, CircularProgress, Container, Divider, Stack, Typography } from "@mui/material";
import { QrCode } from "Components/Common";
import useFetch from "Components/Hooks/useFetch";
import { ROUTE_PROFILE_LOGIN } from "Constants";
import Layout from "Layout";
import { showError } from "Utils";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ProfileViewPage = () => {

  const LabelField = styled("span")({
    fontWeight: "bold",
  });

  const { getSingleUser, loading } = useFetch();
  const [user, setUser] = useState();
  const { id } = useParams();
  const nav = useNavigate();

  useEffect(() => {
    getSingleUser(id)
      .then((user) => {
        setUser(user);
        console.log(user);
      })
      .catch((err) => {
        showError({
          title: "Oops",
          message: err.message || "Failed to get user data",
        }).then(() => nav(ROUTE_PROFILE_LOGIN));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !user) {
    return <Layout>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Layout>;
  }

  return (
    <Layout>

      <Container maxWidth="md">
        <Card>
          <CardContent>
            <Box p={2}>
              <Avatar
                src={user.details.profilePicture}
                sx={{
                  width: 100,
                  height: 100,
                  margin: "0 auto",
                }}
              />
              <Stack spacing={3} sx={{ wordWrap: "break-word" }}>
                <Box />
                <Typography variant="h5" sx={{ textAlign: "center" }}>
                  {user.details.name}
                </Typography>
                <Divider />
                <Typography variant="subtitle1">
                  <LabelField>પેટા-શાખ:</LabelField> {user.details.subCaste}
                </Typography>

                <Typography variant="subtitle1">
                  <LabelField>ગામ:</LabelField> {user.details.city}
                </Typography>

                <Typography variant="subtitle1">
                  <LabelField>પિતાનું નામ:</LabelField> {user.details.fatherName}
                </Typography>

                <Typography variant="subtitle1">
                  <LabelField>માતાનું નામ:</LabelField> {user.details.motherName}
                </Typography>

                <Typography variant="subtitle1">
                  <LabelField>Marital status (વૈવાહિક સ્થિતિ):</LabelField>{" "}
                  {user.details.maritalStatus}
                </Typography>

                <Typography variant="subtitle1">
                  <LabelField>મોબાઈલ નંબર:</LabelField> {user.details.mobileNumber}
                </Typography>

                <Typography variant="subtitle1">
                  <LabelField>આપાતકાલ મોબાઈલ નંબર:</LabelField> {user.details.emergencyNumber}
                </Typography>

                <Typography variant="subtitle1">
                  <LabelField>ઇમેઇલ:</LabelField> {user.details.email}
                </Typography>

                <Typography variant="subtitle1">
                  <LabelField>બ્લડ ગ્રૂપ:</LabelField> {user.details.bloodGroup}
                </Typography>

                <Typography variant="subtitle1">
                  <LabelField>જન્મ તારીખ:</LabelField> {new Date(user.details.birthDate).toLocaleDateString("IN")}
                </Typography>

                <Typography variant="subtitle1">
                  <LabelField>સરનામું:</LabelField> {user.details.address}
                </Typography>

                <Typography variant="subtitle1">
                  <LabelField>અત્યારે હાલ શું કરો:</LabelField> {user.details.currentActivity}
                </Typography>

                <Typography variant="subtitle1">
                  <LabelField>શૈક્ષણિક લાયકાત:</LabelField> {user.details.education}
                </Typography>

                <Typography variant="subtitle1">
                  <LabelField>Hobbies:</LabelField> {user.details.hobbies.join(", ")}
                </Typography>

                <Typography variant="subtitle1">
                  <LabelField>Department:</LabelField> {user.details.department || 'None'}
                </Typography>
                <Divider />
              </Stack>
              <QrCode content={window.location.href} />
            </Box>

          </CardContent>
        </Card>
      </Container>

    </Layout>
  );
};

export default ProfileViewPage;
