import { AccountCircle, ArrowBack, Clear, Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AvatarSection, QrCode, TagsAutoComplete } from "Components/Common";
import useFetch from "Components/Hooks/useFetch";
import {
  BLOOD_GROUP,
  CITY,
  DEPARTMENT_OPTIONS,
  HIGHER_EDU_OPTIONS,
  OTHER_ACTIVITY_OPTIONS,
  SUB_CASTE_OPTIONS,
} from "Constants";
import { formatMobileNumber, showError } from "Utils";
import enIN from "date-fns/locale/en-IN";
import { MuiTelInput } from "mui-tel-input";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";


const Register = () => {
  const { registerUser, loading, result } = useFetch();
  const qrCode = useRef();

  // File Type
  const [photo, setPhoto] = useState(null);

  // String types (exc. birthDate)
  const [name, setName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emergencyNumber, setEmergencyNumber] = useState("");
  const [email, setEmail] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [birthDate, setBirthDate] = useState(null);
  const [address, setAddress] = useState("");
  const [hobbies, setHobbies] = useState([]);
  const [department, setDepartment] = useState("");

  // With Other Options
  const [subCaste, setSubCaste] = useState("");
  const [otherSubCaste, setOtherSubCaste] = useState("");

  const [city, setCity] = useState("");
  const [otherCity, setOtherCity] = useState("");

  const [currentActivity, setCurrentActivity] = useState("");
  const [otherActivity, setOtherActivity] = useState("");

  const [education, setEducation] = useState("");
  const [otherEducation, setOtherEducation] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Check if photo is not uploaded
    if (!photo) {
      showError({
        title: "Oops",
        message: "You forgot to upload profile photo",
      });
      return;
    }

    formData.append("name", name);
    formData.append("fatherName", fatherName);
    formData.append("motherName", motherName);
    formData.append("maritalStatus", maritalStatus);
    formData.append("mobileNumber", formatMobileNumber(mobileNumber));
    formData.append("emergencyNumber", formatMobileNumber(emergencyNumber));
    formData.append("email", email);
    formData.append("bloodGroup", bloodGroup);
    formData.append("birthDate", birthDate);
    formData.append("address", address);
    formData.append("hobbies", JSON.stringify(hobbies));
    formData.append("department", department);

    // Check if Other option is selected
    if (subCaste === "Other") {
      formData.append("subCaste", otherSubCaste);
    } else {
      formData.append("subCaste", subCaste);
    }

    if (city === "Other") {
      formData.append("city", otherCity);
    } else {
      formData.append("city", city);
    }

    if (currentActivity === "Other") {
      formData.append("currentActivity", otherActivity);
    } else {
      formData.append("currentActivity", currentActivity);
    }

    if (education === "Other") {
      formData.append("education", otherEducation);
    } else {
      formData.append("education", education);
    }

    // Send Data through API
    registerUser(formData, photo);
  };

  const handleClear = () => {
    setPhoto(null);
    setName("");
    setFatherName("");
    setMotherName("");
    setMaritalStatus("");
    setMobileNumber("");
    setEmergencyNumber("");
    setEmail("");
    setBloodGroup("");
    setBirthDate(null);
    setAddress("");
    setHobbies([]);
    setDepartment("");
    setSubCaste("");
    setCity("");
    setCurrentActivity("");
    setEducation("");
    setOtherSubCaste("");
    setOtherCity("");
    setOtherActivity("");
    setOtherEducation("");
  };

  const handleSubcasteChange = (e) => {
    setSubCaste(e.target.value);

    if (e.target.value !== "Other") {
      setOtherSubCaste("");
    }
  };
  const handleCityChange = (e) => {
    setCity(e.target.value);

    if (e.target.value !== "Other") {
      setOtherCity("");
    }
  };

  const handleEducationChange = (e) => {
    setEducation(e.target.value);

    // Reset otherEducation field if a different option is selected
    if (e.target.value !== "Other") {
      setOtherEducation("");
    }
  };

  const handleCurrentActivityChange = (e) => {
    setCurrentActivity(e.target.value);

    // Reset "other" field if a different option is selected
    if (e.target.value !== "Other") {
      setOtherActivity("");
    }
  };

  // Scroll to QR Code after generating
  useEffect(() => {
    if (result) {
      qrCode.current.focus();
      qrCode.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center', // Scroll to the center of the element
        inline: 'nearest', // Scroll to the nearest edge of the element 
      });
    }
  }, [result]);

  return (
    <form action="" onSubmit={handleSubmit} onReset={handleClear}>
      <Card sx={{ marginX: 'auto', maxWidth: '750px' }}>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item xs={12}>
            <CardContent>
              {!result ?
                <Stack spacing={2} py={2}>
                  <AvatarSection value={photo} onChange={setPhoto} />
                  <Box />

                  <FormControl fullWidth>
                    <InputLabel id="subcaste-label">{`Subcaste (તમારી પેટા-શાખ)`}</InputLabel>
                    <Select
                      labelId="subcaste-label"
                      id="subcaste"
                      label={`Subcaste (તમારી પેટા-શાખ)`}
                      value={subCaste}
                      onChange={handleSubcasteChange}
                      required
                    >
                      {SUB_CASTE_OPTIONS.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                      <MenuItem value="Other">અન્ય</MenuItem>
                    </Select>
                    {subCaste === "Other" && (
                      <TextField
                        required
                        sx={{ mt: 2 }}
                        label="Other Subcaste (અન્ય પેટા-શાખ)"
                        value={otherSubCaste}
                        onChange={(e) => setOtherSubCaste(e.target.value)}
                      />
                    )}
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="city-label">{`City (ગામ)`}</InputLabel>
                    <Select
                      labelId="city-label"
                      id="city"
                      label={`City (ગામ)`}
                      value={city}
                      onChange={handleCityChange}
                      required
                    >
                      {CITY.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                      <MenuItem value="Other">અન્ય</MenuItem>
                    </Select>
                    {city === "Other" && (
                      <TextField
                        required
                        sx={{ mt: 2 }}
                        label={`Other City (અન્ય ગામ)`}
                        value={otherCity}
                        onChange={(e) => setOtherCity(e.target.value)}
                      />
                    )}
                  </FormControl>

                  <TextField
                    label="Name (તમારું નામ)"
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />

                  <TextField
                    label="Father's Name (પિતાનું નામ)"
                    variant="outlined"
                    value={fatherName}
                    onChange={(e) => setFatherName(e.target.value)}
                    required
                  />

                  <TextField
                    label="Mother's Name (માતાનું નામ)"
                    variant="outlined"
                    value={motherName}
                    onChange={(e) => setMotherName(e.target.value)}
                    required
                  />

                  <FormControl fullWidth required>
                    <InputLabel id="label-marriage-status">
                      Marital status (વૈવાહિક સ્થિતિ)
                    </InputLabel>
                    <Select
                      labelId="label-marriage-status"
                      id="marriage-status"
                      label="Marital status (વૈવાહિક સ્થિતિ)"
                      value={maritalStatus}
                      onChange={(e) => setMaritalStatus(e.target.value)}
                      required
                    >
                      <MenuItem value="Married">Married</MenuItem>
                      <MenuItem value="Engaged">Engaged</MenuItem>
                      <MenuItem value="Unmarried">Unmarried</MenuItem>
                      <MenuItem value="Widow">Widow</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>


                  <MuiTelInput
                    defaultCountry="IN"
                    label="Mobile Number (મોબાઈલ નંબર)"
                    variant="outlined"
                    value={mobileNumber}
                    onChange={(num) => setMobileNumber(num)}
                    required
                  />

                  <MuiTelInput
                    defaultCountry="IN"
                    label="Emergency Number (આપત્કાળીન નંબર)"
                    variant="outlined"
                    value={emergencyNumber}
                    onChange={(num) => setEmergencyNumber(num)}
                    required
                  />

                  <TextField
                    label="Email (ઈ-મેલ આઈડી)"
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />

                  <FormControl variant="outlined" required>
                    <InputLabel>{`Blood Group (બ્લડ ગ્રૂપ)`}</InputLabel>
                    <Select
                      value={bloodGroup}
                      onChange={(e) => {
                        setBloodGroup(e.target.value);
                      }}
                      label="Blood Group (બ્લડ ગ્રૂપ)"
                    >
                      <MenuItem value="Unknown">Unknown</MenuItem>
                      {BLOOD_GROUP.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={enIN}
                  >
                    <DatePicker
                      label="Birth date (જન્મ તારીખ)"
                      openTo="day"
                      views={["year", "month", "day"]}
                      value={birthDate ? new Date(birthDate) : null}
                      format={"dd/MM/yyyy"}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          fullWidth: true,
                          required: true,
                          // error: !isFalsy(errors[getEduKey(level, ID_EDU_START_DATE)]),
                          // helperText: errors[getEduKey(level, ID_EDU_START_DATE)] ?? "",
                        },
                      }}
                      onChange={(date) => setBirthDate(date)}
                    />
                  </LocalizationProvider>

                  <TextField
                    label="Address (સરનામું)"
                    multiline
                    minRows={4}
                    variant="outlined"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  />

                  <FormControl variant="outlined" required>
                    <InputLabel>{`Current Occupation (અત્યારે હાલ શું કરો છો)`}</InputLabel>
                    <Select
                      value={currentActivity}
                      onChange={handleCurrentActivityChange}
                      label="Current Occupation (અત્યારે હાલ શું કરો છો)"
                    >
                      {OTHER_ACTIVITY_OPTIONS.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                      <MenuItem value="Other">અન્ય</MenuItem>
                    </Select>
                    {currentActivity === "Other" && (
                      <TextField
                        required
                        sx={{ mt: 2 }}
                        label="Current Occupation (હાલ શું કરો છો)"
                        value={otherActivity}
                        onChange={(e) => setOtherActivity(e.target.value)}
                      />
                    )}
                  </FormControl>

                  <FormControl variant="outlined" required>
                    <InputLabel>{`Education (શૈક્ષણિક લાયકાત)`}</InputLabel>
                    <Select
                      value={education}
                      onChange={handleEducationChange}
                      label="Education (શૈક્ષણિક લાયકાત)"
                    >
                      {HIGHER_EDU_OPTIONS.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                      <MenuItem value="Other">અન્ય</MenuItem>
                    </Select>
                    {education === "Other" && (
                      <TextField
                        required
                        sx={{ mt: 2 }}
                        label="Other Education (અન્ય શૈક્ષણિક લાયકાત)"
                        value={otherEducation}
                        onChange={(e) => setOtherEducation(e.target.value)}
                      />
                    )}
                  </FormControl>

                  <TagsAutoComplete
                    label="Hobbies"
                    placeholder="Add your own hobbies"
                    onChange={(newHobbies) => setHobbies(newHobbies)}
                    value={hobbies}
                  />

                  <FormControl variant="outlined">
                    <InputLabel>{`Department (વિભાગ)`}</InputLabel>
                    <Select
                      value={department}
                      onChange={(e) => {
                        setDepartment(e.target.value);
                      }}
                      label="Department (વિભાગ)"
                    >
                      <MenuItem value="">None</MenuItem>
                      {DEPARTMENT_OPTIONS.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Box />

                  <Box
                    flexDirection={"row"}
                    justifyContent={"center"}
                    display={"flex"}
                    flexWrap={'wrap'}
                    gap={{ xs: 2, md: 8 }}
                  >
                    <Button
                      startIcon={<Clear />}
                      disabled={loading}
                      type="reset"
                      variant="contained"
                      color="error"
                    >
                      Clear
                    </Button>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={loading}
                      loadingPosition="start"
                      startIcon={<Save />}
                      sx={{
                        backgroundColor: '#27374D',
                        ":hover": {
                          backgroundColor: '#4c5c72'
                        }
                      }}
                    >
                      <span>{loading ? "Submitting..." : "Submit"}</span>
                    </LoadingButton>
                  </Box>
                </Stack>
                :
                <Box ref={qrCode} justifyContent={"center"} display={"flex"} flexDirection={"column"}>
                  <QrCode
                    content={result}
                    fileName={result.substring(result.lastIndexOf("/") + 1)}
                  />
                  <Button
                    LinkComponent={Link}
                    to={result}
                    startIcon={<AccountCircle />}
                    sx={{ mt: 2, maxWidth: "300px", mx: 'auto' }}
                    fullWidth
                    variant="contained"
                    color="info"
                  >
                    View Profile
                  </Button>
                  <Button
                    startIcon={<ArrowBack />}
                    onClick={() => window.location.reload()}
                    sx={{ mt: 2, maxWidth: "300px", mx: 'auto' }}
                    fullWidth
                    variant="outlined"
                  >
                    Back to Registration
                  </Button>
                </Box>
              }
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </form>
  );
};

export default Register;
