import  Login  from "Components/Forms/Login";
import Layout from "Layout";
import { Box, Container } from "@mui/material";

function ProfileLoginPage() {
    return (
        <Layout>
            <Container sx={{
                minHeight: "calc(100dvh - 142px)",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column"
            }}>
                <Box width={"100%"}>
                    <Login />
                </Box>
            </Container>

        </Layout>
    );
}

export default ProfileLoginPage;