import { S3Client } from "@aws-sdk/client-s3";

const s3 = new S3Client({
    region: 'auto',
    endpoint: `https://11f8c0607302c589ec06a0b3c1a8fd3d.r2.cloudflarestorage.com`,
    credentials: {
        accessKeyId: "7001ad5f800eb452899f5752c1055286",
        secretAccessKey: "4fa0166e0ee700567c9a6017a7120c2e05473162685828e5f9501aa826436015",
    }
});

export default s3;
