import {
  AccountCircle,
  AdminPanelSettings,
  Call,
  EditNote,
  Email,
  Instagram,
  Menu
} from "@mui/icons-material";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  LINK_CONTACT,
  LINK_EMAIL,
  LINK_INSTAGRAM,
  ROUTE_ADMIN,
  ROUTE_PROFILE_LOGIN,
  ROUTE_REGISTRATION,
  SITE_TITLE
} from "Constants";
import { useState } from "react";
import { Link } from "react-router-dom";

const drawerWidth = 240;
const navItems = [
  { label: "Register", path: ROUTE_REGISTRATION, icon: EditNote },
  { label: "View Profile", path: ROUTE_PROFILE_LOGIN, icon: AccountCircle },
  { label: "Admin Login", path: ROUTE_ADMIN, icon: AdminPanelSettings },
];

const iconItems = [
  { icon: Instagram, path: LINK_INSTAGRAM },
  // { icon: YouTube, path: LINK_YOUTUBE },
  // { icon: Facebook, path: LINK_FACEBOOK },
  { icon: Email, path: LINK_EMAIL },
  { icon: Call, path: LINK_CONTACT },
];

export default function NavBar() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center", color: 'white' }}>
      <Button LinkComponent={Link}
        to={"/"}
        sx={{ my: 2 }}
        color="inherit"
      >{SITE_TITLE}
      </Button>
      <Divider color="white" sx={{ width: '90%', mx: "auto" }} />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton component={Link} to={item.path}>
              <ListItemIcon sx={{ justifyContent: "center", color: 'white' }}>
                <item.icon color="inherit" />
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent={"space-evenly"}
            sx={{ ml: "3px" }}
          >
            {iconItems.map((item, idx) => (
              <IconButton
                // size="large"
                LinkComponent={Link}
                to={item.path}
                target="_blank"
                rel="noopener noreferrer"
                edge="end"
                color="inherit"
                key={idx}
                sx={{ mx: 0.5 }}
              >
                <item.icon />
              </IconButton>
            ))}
          </Stack>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      <AppBar sx={{ backgroundColor: "#27374D" }} variant="elevation">
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to={ROUTE_REGISTRATION}
            sx={{
              mr: 2,
              fontWeight: 700,
              fontSize: { xs: "medium", sm: "large", md: "larger" },
              letterSpacing: { xs: ".1rem", sm: ".2rem", md: ".3rem" },
              textDecoration: "none",
              color: "inherit",
            }}
            flexGrow={1}
          >
            {SITE_TITLE}
          </Typography>

          {iconItems.map((item, idx) => (
            <IconButton
              // size="large"
              LinkComponent={Link}
              to={item.path}
              target="_blank"
              rel="noopener noreferrer"
              edge="end"
              color="inherit"
              key={idx}
              sx={{ mx: 0.5, display: { xs: "none", md: "flex" } }}
            >
              <item.icon />
            </IconButton>
          ))}

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {navItems.map((item) => (
              <Button
                className="hover-underline-animation"
                key={item.label}
                component={Link}
                to={item.path}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {item.label}
              </Button>
            ))}
          </Box>

          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: "flex", md: "none" } }}
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          anchor="right"
          container={container}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "linear-gradient(135deg, #2a374b, #27374D)"
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}
