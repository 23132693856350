
import { Refresh } from "@mui/icons-material";
import { Avatar, Box, IconButton } from "@mui/material";
import useFetch from "Components/Hooks/useFetch";
import { THIS_URL } from "Constants";
import { showError } from "Utils";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import QrCode from "./QrCode";
import { TableSkeleton } from "./TableSkeletons";


function AllUsersDatagrid() {

    const [users, setUsers] = useState([]);
    const { loading, getAllUsers } = useFetch();


    const renderImage = (src, tableMeta) => {
        return <a href={src} download target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <Avatar
                src={src}
                alt="Profile Picture" loading="lazy"
                variant="rounded"
                style={{ width: 50, height: 50 }}
            />
        </a>
    }

    const renderQrcode = (value, tableMeta) => {
        if (!value) {
            return <Box />;
        }
        return <QrCode renderSize={56} content={`${THIS_URL}/profile/${value}`} fileName={value} withDownloadButton={false} />
    }

    const renderBirthDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('IN');
    }

    const columns = [
        { name: 'id', label: 'Sr no.', options: { filter: true, sort: true, width: 100 } },
        { name: 'name', label: 'Name', options: { filter: true, sort: true, width: 200 } },
        { name: 'fatherName', label: 'Father Name', options: { filter: true, sort: true, width: 200 } },
        { name: 'motherName', label: 'Mother Name', options: { filter: true, sort: true, width: 200 } },
        { name: 'maritalStatus', label: 'Marital Status', options: { filter: true, sort: true, width: 200 } },
        { name: 'mobileNumber', label: 'Mobile Number', options: { filter: true, sort: true, width: 300 } },
        { name: 'emergencyNumber', label: 'Emergency Number', options: { filter: true, sort: true, width: 200 } },
        { name: 'email', label: 'Email', options: { filter: true, sort: true, width: 200 } },
        { name: 'bloodGroup', label: 'Blood Group', options: { filter: true, sort: true, width: 200 } },
        { name: 'birthDate', label: 'Birth Date', options: { filter: true, sort: true, width: 200, customBodyRender: renderBirthDate } },
        { name: 'address', label: 'Address', options: { filter: true, sort: true, width: 200 } },
        { name: 'hobbies', label: 'Hobbies', options: { filter: true, sort: true, width: 200 } },
        { name: 'department', label: 'Department', options: { filter: true, sort: true, width: 200 } },
        { name: 'subCaste', label: 'Sub Caste', options: { filter: true, sort: true, width: 200 } },
        { name: 'city', label: 'City', options: { filter: true, sort: true, width: 200 } },
        { name: 'currentActivity', label: 'Current Activity', options: { filter: true, sort: true, width: 200 } },
        { name: 'education', label: 'Education', options: { filter: true, sort: true, width: 200 } },
        { name: 'profilePicture', label: 'Profile Picture', options: { filter: false, sort: false, width: 200, customBodyRender: renderImage } },
        { name: 'mobileNumber', label: 'QR Code', options: { filter: false, sort: false, width: 200, customBodyRender: renderQrcode } },
    ];



    const rows = users.map((row, index) => ({
        id: index + 1,
        ...row
    }));


    const fetchUsers = () => {
        getAllUsers()
            .then(users => {
                setUsers(users);
            })
            .catch(err => {
                showError({ title: 'Failed to Get Users', message: err.message }).then(() => window.location.reload());
            });
    }

    useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <Box display={loading ? 'none' : 'block'}>
                <MUIDataTable
                    title="Registered Users"
                    columns={columns}
                    data={rows}
                    options={{
                        customToolbar: () => <IconButton onClick={fetchUsers}><Refresh /></IconButton>,
                        rowsPerPageOptions: [5, 10, 50],
                        rowsPerPage: 5,
                        selectableRows: 'none',
                        elevation: 0,
                        responsive: 'standard',
                        download: false,
                        print: false,
                    }}
                />
            </Box>
            {loading && <TableSkeleton rows={5} columns={8} />}
        </>
    );
}

export default AllUsersDatagrid;