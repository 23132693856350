

// ################################ SITE CONSTANTS ################################

export const SITE_TITLE = "Kutch Prajapti Yuva";

// ################################ ROUTES ################################

export const ROUTE_ADMIN = "/admin";
export const ROUTE_REGISTRATION = "/register"
export const ROUTE_PROFILE_LOGIN = "/profile";
export const ROUTE_PROFILE_VIEW = "/profile/:id";

// ################################ API ENDPOINTS ################################

export const API_ENDPOINT = process.env.NODE_ENV === 'production' ?
    process.env.REACT_APP_API_ENDPOINT : "http://localhost:8080";

export const API_REGISTER = `${API_ENDPOINT}/register`;
export const API_LOGIN = `${API_ENDPOINT}/login`;
export const API_USERS = `${API_ENDPOINT}/users`;

// ################################ UI Constants ################################

export const APP_BAR_MOBILE = 32;
export const APP_BAR_DESKTOP = 40;

// ################################ Link Constants ################################

export const THIS_URL = process.env.NODE_ENV === 'production' ?
    process.env.REACT_APP_THIS_URL : 'http://localhost:3000';

export const LINK_INSTAGRAM = "https://www.instagram.com/kutch_prajapati_yuva_kalol/";
export const LINK_CONTACT = "tel:+919998134806";
export const LINK_FACEBOOK = "https://google.com";
export const LINK_YOUTUBE = "https://google.com";
export const LINK_EMAIL = "mailto:kalolkutchiyuvagroup@gmail.com";
export const PLACEHOLDER_USER = '/assets/placeholder.png';