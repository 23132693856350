import { LoginOutlined, Translate } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import useFetch from "Components/Hooks/useFetch";
import { PASSWORD_HELP_ENGLISH, PASSWORD_HELP_GUJARATI } from "Constants";
import { formatMobileNumber, showError } from "Utils";
import { MuiTelInput } from "mui-tel-input";
import { useState } from "react";

function Login() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [helpLanguage, setHelpLanguage] = useState("gujarati");
  const { loginUser, loading } = useFetch();

  // Toggles the Language
  const toggleHelpLanguage = () => setHelpLanguage(helpLanguage === 'gujarati' ? 'english' : 'gujarati');

  const handleReset = () => {
    setMobileNumber("");
    setPassword("");
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {};

    // Validation
    formData['mobileNumber'] = formatMobileNumber(mobileNumber);
    if (formData['mobileNumber'].trim() === "") {
      showError({ message: "Please enter a valid mobile number!" }).then(handleReset);
      return;
    }

    formData['password'] = password;
    if (password.length < 8) {
      showError({ message: "Please enter a valid password!" }).then(handleReset);
      return;
    }

    // Submit the form
    loginUser(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card
        sx={{
          marginX: "auto",
          maxWidth: "350px",
          padding: 2,
          mt: 4,
        }}
      >
        <CardHeader
          title={
            <Typography sx={{ textAlign: "start" }} variant="h5">
              Login
            </Typography>
          }
        />
        <CardContent>
          <Box
            maxWidth={"350px"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            marginX="auto"
            rowGap={3}
          >
            <MuiTelInput
              defaultCountry="IN"
              label={helpLanguage === 'gujarati' ? "તમારો મોબાઈલ નંબર" : "Mobile number"}
              variant="outlined"
              value={mobileNumber}
              onChange={(num) => setMobileNumber(num)}
              required
            />
            <TextField
              variant="outlined"
              type="password"
              label={helpLanguage === 'gujarati' ? "તમારો પાસવર્ડ" : "Password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              required
              helperText={helpLanguage === 'gujarati' ? PASSWORD_HELP_GUJARATI : PASSWORD_HELP_ENGLISH}
            />
          </Box>
        </CardContent>
        <CardActions>
          <IconButton onClick={toggleHelpLanguage}>
            <Translate />
          </IconButton>
          <Box marginLeft="auto">
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              loadingPosition="start"
              startIcon={<LoginOutlined />}
              sx={{
                backgroundColor: '#27374D',
                ":hover": {
                  backgroundColor: '#4c5c72'
                }
              }}
            >
              {helpLanguage === 'gujarati' ? "લોગિન" : "Login"}
            </LoadingButton>
          </Box>
        </CardActions>
      </Card>
    </form>
  );
}

export default Login;
