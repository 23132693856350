import { Container } from "@mui/material";
import Register from "Components/Forms/Register";
import Layout from "Layout";

function RegistrationPage() {
  return (
    <Layout>
      <Container sx={{ py: 2, }}>
        <Register />
      </Container>
    </Layout>
  );
}

export default RegistrationPage;
