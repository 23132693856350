import {
  Autocomplete,
  Chip,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { HOBBY_OPTIONS } from "Constants";
import { useState } from "react";

const TagsAutoComplete = ({ onChange, value, label, placeholder }) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue) {
      event.preventDefault();
      onChange([...value, removeAddParams(inputValue)]);
      setInputValue("");
    }
  };

  const handleDeleteTag = (tagToDelete, index) => () => {
    onChange((selectedTags) =>
      selectedTags.filter((tag, idx) => tag === tagToDelete && idx === index)
    );
  };

  const handleSelectedTagsChange = (event, value) => {
    value = value.map((val) => removeAddParams(val));
    onChange(value);
  };

  return (
    <Autocomplete
      fullWidth
      freeSolo
      noOptionsText={'Press "Enter" to add'}
      multiple
      id="reasons-outlined"
      options={HOBBY_OPTIONS}
      filterSelectedOptions
      filterOptions={(options, params) => {
        const filtered = createFilterOptions()(options, params);
        if (params.inputValue !== "") {
          filtered.push(`Add "${params.inputValue}"`);
        }
        return filtered;
      }}
      value={value}
      onChange={handleSelectedTagsChange}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      onKeyDown={handleKeyDown}
      renderTags={(value, getTagProps) =>
        value.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={handleDeleteTag(tag, index)}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};

function removeAddParams(str) {
  const addRegex = /^Add "(.*)"$/;
  const match = addRegex.exec(str);
  if (match) {
    return match[1];
  }
  return str;
}

export default TagsAutoComplete;
