import { Download } from "@mui/icons-material";
import { Box, Button, Stack } from "@mui/material";
import QrCodeWithLogo from "qrcode-with-logos";
import { useEffect, useRef, useState } from "react";

function QrCode({
  content = window.location.href,
  size = 300, renderSize = size,
  fileName = "Profile QR Code",
  withDownloadButton = true
}) {
  const imageRef = useRef();

  const [qr, setQr] = useState(null);

  useEffect(() => {
    const qrObj = new QrCodeWithLogo({
      image: imageRef.current,
      content: content,
      width: size,
      nodeQrCodeOptions: {
        errorCorrectionLevel: "H", // Highest compression for best error correction
        color: {
          // light: "#5A5A5A",
          // dark: "#FFD800"
        },
      },
      // logo: {
      //   logoSize: 0.2,
      //   src: logoUrl,
      //   borderSize: 0.02,
      //   // bgColor: "#5A5A5A"
      // },
    });

    qrObj.toImage().then((val) => { });
    setQr(qrObj);
  }, [content, size]);

  const handleQrDownload = () => {
    qr.downloadImage(fileName);
  };

  if (!withDownloadButton) {
    return (
      <img
        ref={imageRef}
        height={renderSize}
        width={renderSize}
        alt="QR Code"
        onClick={handleQrDownload} />
    );
  }

  return (
    <Box width={"100%"} justifyContent={"center"} display={"flex"}>
      <Stack direction="column" rowGap={2}>
        <img ref={imageRef} alt="QR Code" height={renderSize} width={renderSize} />
        <Button startIcon={<Download />} variant="contained" color="success" onClick={handleQrDownload}>
          Download
        </Button>
      </Stack>
    </Box>
  );
}

export default QrCode;
