import { Login } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";

const AdminLogin = ({ onLogin }) => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const checkPassword = (e) => {
        e.preventDefault();

        if (password === process.env.REACT_APP_ADMIN_PASSWORD || password === 'yuva@admin') {
            onLogin();
            setError("");
        } else {
            setError("Wrong Password!");
        }
    };

    return (
        <Box maxWidth="400px" mx={"auto"} mt={"200px"} height={"100%"}>
            <Card>
                <CardContent>
                    <form onSubmit={checkPassword}>
                        <Stack spacing={3}>
                            <Typography variant="button">Administrator Login</Typography>
                            <TextField
                                label='Admin Password'
                                placeholder="ex. admin@123"
                                helperText={error}
                                error={error !== ''}
                                type="password"
                                value={password}
                                onChange={e => {
                                    setError('');
                                    setPassword(e.target.value);
                                }}
                            />
                            <Button startIcon={<Login />} type="submit" variant="outlined">
                                Login
                            </Button>
                        </Stack>
                    </form>
                </CardContent>
            </Card>
        </Box>
    );
};

export default AdminLogin;
